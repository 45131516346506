if (window.innerWidth < 1260) {
  const contactMenuItem = document.querySelector(".menu-item-type-custom");

  if (contactMenuItem) {
    contactMenuItem.addEventListener("click", () => {
      const menu = document.querySelector(".menu");
      const html = document.querySelector("html");
      const hamburger = document.querySelector("#toggle");

      if (menu.style.display === "block") {
        menu.style.display = "none";
        html.style.overflowY = "unset";
        hamburger.classList.remove("open");
      } else {
        menu.style.display = "block";
        html.style.overflowY = "hidden";
        hamburger.classList.add("open");
      }
    });
  }
}
